import { COOKIE_AUTH_TOKEN } from '@guess-the-rose/core'
import { UserDocument } from '@guess-the-rose/firestore'
import { noop, parseName } from '@guess-the-rose/utils'
import cookie from 'js-cookie'
import React, {
  FC,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react'

import { AUTH_REDIRECT_URL } from '../const'
import {
  safeLocalStorage,
  useFieldValue,
  useFirebaseAnalytics,
  useFirebaseAuth,
  useFirestore,
} from '../lib'

export type AuthState = UnauthedState | AuthedState
const AuthStateContext = createContext<AuthState | undefined>(undefined)

type UnauthedState = {
  isAuthed: false
  isLoading: boolean
  user: null
  token: undefined
  logout: () => Promise<void>
}

type AuthedState = {
  isAuthed: true
  isLoading: boolean
  user: UserDocument
  token: string
  logout: () => Promise<void>
}

export const initialAuthState: AuthState = {
  isAuthed: false,
  isLoading: true,
  user: null,
  token: undefined,
  logout: async () => noop(),
}

export const AuthProvider: FC = ({ children }) => {
  const [authState, setAuthState] = useState<AuthState>(initialAuthState)
  const auth = useFirebaseAuth()
  const analytics = useFirebaseAnalytics()
  const db = useFirestore()
  const fieldValue = useFieldValue()

  useEffect(() => {
    const logout = async () => {
      cookie.remove(COOKIE_AUTH_TOKEN)
      // Remove just in case it's still there
      safeLocalStorage()?.removeItem(AUTH_REDIRECT_URL)
      await auth.signOut()
      // Refresh the page to clear out the cache and make sure everything took holt
      window.location.href = '/'
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    let unsubscribeFromUserListener = () => {}

    const unsubscribe = auth.onAuthStateChanged(async (userAuth) => {
      if (!userAuth) {
        setAuthState({
          isAuthed: false,
          isLoading: false,
          user: null,
          token: undefined,
          logout,
        })
      } else {
        analytics?.setUserId(userAuth.uid)
        safeLocalStorage()?.removeItem(AUTH_REDIRECT_URL)

        const [firstName, lastName] = parseName(userAuth.displayName)

        db.collection('/users')
          .doc(userAuth.uid)
          .update({
            lastLogin: fieldValue.serverTimestamp(),
            displayName: userAuth.displayName ?? null,
            firstName,
            lastName,
          })
          .catch((_err) => {
            // Do nothing. This won't succeed on the initial login.
            // It will succeed on the next redirect
          })

        unsubscribeFromUserListener = await db
          .collection('users')
          .doc(userAuth.uid)
          .onSnapshot(
            async (doc) => {
              const userInfo = doc.data() as UserDocument
              const token = await auth?.currentUser?.getIdToken()

              // We don't handle the error case because when a user is created a cloud function is fired and
              // sometimes it doesn't complete by the time this is called. However, since it's a subscription
              // it'll eventually come through for us.
              if (userInfo) {
                analytics?.setUserProperties({ tier: userInfo.tier })

                if (token) {
                  cookie.set(COOKIE_AUTH_TOKEN, token, { expires: 1 })
                }

                setAuthState({
                  isAuthed: true,
                  isLoading: false,
                  user: userInfo,
                  // @ts-ignore Token will be there since in callback
                  token,
                  logout,
                })
              }
            },
            (e) => {
              // toast(`Error: ${e.message}`)
              console.log('Error', e)
            },
          )
      }
    })

    return () => {
      unsubscribe()
      unsubscribeFromUserListener()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <AuthStateContext.Provider value={authState}>
      {children}
    </AuthStateContext.Provider>
  )
}

export const useAuth = () => {
  const context = useContext(AuthStateContext)
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider')
  }
  return context
}
