import { QuestionType } from '@guess-the-rose/firestore'

export const AUTH_REDIRECT_URL = 'authRedirectURL'

// Order questions like this every week to guard against CMS.
export const QUESTION_ORDER_ARRAY: QuestionType[] = [
  'weeklyLineup',
  'multiSelect',
  'multipleChoice',
  'numeric',
  'boolean',
]
