import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/analytics'

import { Fuego } from '@nandorojo/swr-firestore'
import firebase from 'firebase/app'

const config = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
  measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
}

let fuego: Fuego

export function initFirebase() {
  fuego = new Fuego(config)

  // To enable analytics. https://firebase.google.com/docs/analytics/get-started
  if (typeof window !== 'undefined' && 'measurementId' in config) {
    firebase.analytics()
  }

  return fuego
}

export function useFirestore() {
  return fuego.db
}

export function useFuego() {
  return fuego
}

export function useFirebaseAnalytics() {
  if (typeof window !== 'undefined' && 'measurementId' in config) {
    // To enable analytics. https://firebase.google.com/docs/analytics/get-started
    return firebase.analytics()
  }
}

export function useFirebaseAuth() {
  return fuego.auth()
}

export function useFieldValue() {
  return firebase.firestore.FieldValue
}
