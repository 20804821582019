import { CurrentSeasonStatus } from '@guess-the-rose/firestore'

export * from './initFirebase'
export * from './handleLoadingError'

export const safeLocalStorage = () => {
  if (typeof window !== 'undefined') {
    return localStorage
  }
}

export const isBeforeSeasonStart = (
  currentSeasonStatus: CurrentSeasonStatus,
) => {
  return (['preseason', 'drafting'] as CurrentSeasonStatus[]).includes(
    currentSeasonStatus,
  )
}

export const isInOrAfterSeason = (currentSeasonStatus: CurrentSeasonStatus) => {
  return (['playing', 'complete'] as CurrentSeasonStatus[]).includes(
    currentSeasonStatus,
  )
}
