import { SkeletonProps } from '@chakra-ui/core'
import { times } from '@guess-the-rose/utils'
import { Error, Skeleton, Spinner } from '@guess-the-rose/web-shared'
import React from 'react'

export const handleLoadingError = ({
  error,
  data,
  showSpinner = false,
  skeletons = 3,
  skeletonProps = {},
}: {
  data: any
  error: any
  showSpinner?: boolean
  skeletons?: number
  skeletonProps?: SkeletonProps
}) => {
  if (error) {
    return (
      <Error>
        {error.code === 'permission-denied'
          ? 'Permission denied.'
          : error?.message
          ? error.message
          : 'Something went wrong.'}
      </Error>
    )
  }

  if (!data) {
    return showSpinner ? (
      <Spinner superCentered />
    ) : (
      <>
        {times((i) => (
          <Skeleton height="20px" key={i} my="10px" {...skeletonProps} />
        ))(skeletons)}
      </>
    )
  }
}
