import { EntryDocument } from '@guess-the-rose/firestore'
import { compose, prop, sortBy, toLower } from '@guess-the-rose/utils'
import React, {
  FC,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react'

import { useAuth } from '../context'
import { useFirestore } from '../lib'
import { WithID } from '../types'

export type EntryDocumentWithID = WithID<EntryDocument>

type EntriesProvider = {
  error: any
  entries: EntryDocumentWithID[] | undefined
}

const UseEntriesContext = createContext<EntriesProvider | undefined>(undefined)

export const EntriesProvider: FC = ({ children }) => {
  const { user } = useAuth()
  const [{ entries, error }, setState] = useState<EntriesProvider>({
    entries: undefined,
    error: undefined,
  })
  const db = useFirestore()

  useEffect(() => {
    if (!user) return

    const unregister = db
      .collectionGroup('entries')
      .where('createdBy', '==', user.userID)
      .onSnapshot((snap) => {
        const data: EntryDocumentWithID[] = []

        snap.forEach((d) => {
          data.push({ id: d.id, ...d.data() } as EntryDocumentWithID)
        })

        setState({
          entries: sortBy(compose(toLower, prop('name')))(data),
          error: undefined,
        })
      })

    return () => {
      unregister()
    }
  }, [db, user])

  return (
    <UseEntriesContext.Provider value={{ entries, error }}>
      {children}
    </UseEntriesContext.Provider>
  )
}

export const useEntries = () => {
  const context = useContext(UseEntriesContext)
  if (context === undefined) {
    throw new Error('useEntries must be used within a UseEntriesContext')
  }
  return context
}
